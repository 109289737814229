import React, {useContext, useEffect, useState, useCallback} from 'react'
import './style.scss'
import { Link } from 'gatsby'
import NaviContext from '../../../context/NaviContext'
import { debounce } from 'lodash'
import { useStaticQuery, graphql } from "gatsby"

import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'

function InfoBar(props) {

  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query {
        wordpressAcfOptions {
          options {
						mru_portal {
							name
							url
						}
          }
        }
      }
    `
  )

	const mru_portal_link = wordpressAcfOptions?.options?.mru_portal?.url
	const mru_portal_label = wordpressAcfOptions?.options?.mru_portal?.name

	const naviContext = useContext(NaviContext)
    // console.log("important info", props.wordpressAcfOptions.options.event_bar)
    const slug = naviContext.locationPathname

    const [ searchVisible, setSearchVisible ] = useState(false)
    const [ dropDownVisible, setDropDownVisible ] = useState(false)
   
    useEffect(() => {
        if(slug === '/') 
            setDropDownVisible(false)
        else 
            setDropDownVisible(true)
        
    }, [slug])


    // Search via WP API
	const [response, setResponse] = useState([])
	const [searchOpen, setSearchOpen] = useState(true)
	const [isLoading, setLoading] = useState(false)    
    
    const [userQuery, setUserQuery] = useState("")
    const updateQuery = () => {
        // A search query api call.
        sendQuery(userQuery)
    }

    const delayedQuery = useCallback(debounce(updateQuery, 750), [userQuery])

    const onChange = e => {
        setUserQuery(e.target.value)
    }

    function sendQuery(payload) {
        if(payload) {
            const params = new URLSearchParams({ search: payload })
            const url = `https://cms.mru.edu/wp-json/wp/v2/search?${params}`
            //console.log('ran')
            axios.get(url)
            .then(response => {
                setSearchOpen(true)
                //console.log('status', response.data.status)
                console.log(response.data)
                    if(response.data) {
                        setResponse(response.data)
                    }
                    setLoading(false)
                }
            )
            .catch(errors => {
                    console.log(errors)
                }
            )
        }
    }

    const htmlDecode = (input) => {
        const e = document.createElement('div');
        e.innerHTML = input;
        return e.innerText
    }

    useEffect(() => {
        delayedQuery()
     
        // Cancel the debounce on useEffect cleanup.
        return delayedQuery.cancel
    }, [userQuery, delayedQuery])


    const searchResults = <ul className="search-results">{response?.map((node,i) => {

        let url = node.url.replace('https://cms.mru.edu', '')
        let title = node.title

        if (title === 'Grants' || title === 'Loans') {
            url = '/financial-aid'
        }

        if (title === 'Catalog' || title === 'University Catalog' || title === 'Document Request Form') {
            url = '/office-of-the-registrar'
        }

        if (url === '/archives/2044') {
            return false
        }

        return (
            <li key={i}>
                <Link to={url}>
                    {htmlDecode(title)}
                </Link>
            </li>
        )
    })}
    </ul>
    

    return (
        <>
            <div className="info-bar">
                <div className="first-line">
                    <div className="nav">
                        <div className="important disabled">
                            <span className="item">CAMPUS CLOSED DUE TO HURRICANE ISAIAS</span>
                        </div>
                        <div className="group group-1">
                            <span className="item"><a href="tel:3054429223">(305)442-9223</a></span>
                            <span className="item"><a href="mailto:info@mru.edu">info@mru.edu</a></span>
                            <div className="social-icon-container">
                                <div className='icon hint--rounded hint--bottom' data-hint='facebook'>
                                    <a href='https://www.facebook.com/miamiregionaluniversity/' target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </div>
                                <div className='icon hint--rounded hint--bottom' data-hint='instagram'>
                                    <a href='https://www.instagram.com/miamiregionaluniversity/?igshid=caquoamzbesu' target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </div>
                            </div>    
                        </div>
                        <div className="group group-2">
                            <span className="item"><Link to="/location">Contact</Link></span>
                            <span className="separator"></span>
                            <span className="item"><Link to="/consumer-information">Consumer info</Link></span>
                            <span className="separator"></span>
                            <span className="item"><a href="https://portal.mru.edu/Applicant/InfoRequest.aspx" target="_blank">Enroll now</a></span>
                            <span className="separator"></span>
                            <span className="item"><Link to="/continuing-education">Continuing ed</Link></span>
                            <span className="separator"></span>
                            <span className="item"><a href="mailto:HR@MRU.EDU" target="_blank" rel="noreferrer noopener">Jobs</a></span>
                            <span className="separator"></span>
                            <span className="item"><a href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=4345a7b9-9a63-4910-a426-35363201d503&redirect_uri=https%3A%2F%2Fwww.office.com%2Flanding&response_type=code%20id_token&scope=openid%20profile&response_mode=form_post&nonce=637629131999306084.NmM2MzdiOGYtY2JkMi00NzllLThlMDQtZDY1Yjg3YzQ4ZjhhYjAwMzc4ZjYtN2ZkYS00NzkzLTlkYzEtOGNiOWY2ZjBiMWM0&ui_locales=en-US&mkt=en-US&state=JAYec0_rv5QNeww0gjwZA5utU43_GZxSRrwupzckXRykslTlUOKwTHBWQ3mB-p99ohbsfhAogamtBPFqR7Ks-Jeq5rgZFeTCm4FxioEfKwt5p8RHEJBK9KN33QLhsRYSHiKwNMMeTMmwlf3QeVZB17V9WXaPtHuOId9Ad-56NbaYwFTkmixep7gZ6zDyhv8mAzDT0DUbZpopvp9adDOXlnlFEQNn6k4hqbrlbZrfHnXp-lyhArG0HxDGzGSLvqZGU_VIa_AOjSSg26d-9ua07Q&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=6.8.0.0#" target="_blank" rel="noreferrer noopener">STUDENT EMAIL</a></span>
                        </div>
                        <div className="group group-3">
                            <div className='icon search-trigger' onClick={() => setSearchVisible(!searchVisible)}>  
                                <button rel="noreferrer noopener">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div> 
                        </div>
                        <div className="group group-4" rel="noreferrer noopener">
                          <a target="_blank"  rel="noreferrer noopener" href={mru_portal_link ? mru_portal_link : "#"}>
                            <div className='icon'>  
                                <FontAwesomeIcon icon={faUser} />
                            </div> 
                            <span className="item">
															{mru_portal_label ? 
                                mru_portal_label
															: 
																null
															}
                            </span>
                          </a>
                        </div>
                    </div>
                </div>

                <div className={searchVisible ? "search-container active" : "search-container"}>
                    <div className='icon search-icon'>
					    <button rel="noreferrer noopener">
						    <FontAwesomeIcon icon={faSearch} />
					    </button>
				    </div> 
                    <div className="search">
                        <input type="text" value={userQuery} onChange={onChange}></input>
                    </div>
                    {searchOpen ? searchResults : null}
                    <div className='icon search-icon'>
					    <button rel="noreferrer noopener" onClick={() => setSearchOpen(false)}>
						    <FontAwesomeIcon icon={faTimes} />
					    </button>
				    </div> 
                </div>
                
                <div className={dropDownVisible ? "dropdown active" : "dropdown"}>
                    <div className="dropdown-header" >
                        <button className="dropdown-btn" >CHOOSE A SCHOOL</button>
                        <div className='dropdown-icon'>
						    <FontAwesomeIcon icon={faAngleDown} />
				        </div> 
                    </div>
                    <div className="dropdown-content">
                        <Link to="/education">EDUCATION</Link>
                        <Link to="/business">BUSINESS</Link>
                        <Link to="/the-school-of-arts-sciences">THE SCHOOL OF ARTS & SCIENCES</Link>
                        <Link to="/nursing">NURSING</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoBar